.squareBox {
  position: absolute;
  width: 12.5%;
  height: 12.5%;
}

.squareOverlay {
  position: absolute;
  height: 12.5%;
  width: 12.5%;
  opacity: 0.4;
  /* border: 3px solid hsla(0, 0%, 20%, 0.45); */
  /* border: 3px solid rgba(33, 33, 33, 0.4); */

  /* background-color: #5da327; */
  /* background-color: yellowgreen; */
  /* background-color: hsl(60, 78%, 60%); */
  background-color: hsl(50, 77%, 60%);
}

.squareOverlayOrange {
  composes: squareOverlay;
  background-color: hsl(26, 77%, 62%);
}

.squareOverlayYellow {
  composes: squareOverlay;
  background-color: hsl(50, 77%, 60%);
}

/* .squareOverlayForStaunty {
  composes: squareOverlay;
  background-color: hsl(50, 77%, 60%);
} */

.highlight {
  position: absolute;
  height: 12.5%;
  width: 12.5%;
  opacity: 0.5;
  background-color: #6f95ba;
}

.lastMove {
  position: absolute;
  opacity: 0.4;
  /* background-color: #7bcf3a; */
  /* background-color: yellowgreen; */
  /* background-color: hsl(94, 63%, 44%); */
  /* background-color: hsl(60, 78%, 60%); */
  /* background-color: hsl(54, 73%, 60%); */
  /* background-color: #5da327; */
  /* background-color: yellowgreen; */
  /* background-color: #f1f142; */
  /* background-color: lightblue; */

  background-color: hsl(26, 72%, 62%);
  /* background-color: hsl(50, 68%, 65%); */

  /* opacity: 0.4;
  border: 3px solid #00aa00; */
  /* border: 4px solid rgb(33, 111, 45); */
  /* background-color: rgb(33, 111, 45); */

  /* background-color: rgb(252 205 187); */
  /* background-color: hsl(40, 77%, 58%); */

  /* opacity: 0.4; */
  /* border: 3px solid rgba(77, 77, 77, 0.7);
  background-color: transparent; */

  /* background-color: #095209; */
  /* box-shadow: inset 0 0 3px #222; */
  /* background-color: hsl(29, 73%, 60%); */

  z-index: 4;
  width: 12.5%;
  height: 12.5%;
}

.lastMoveOrange {
  composes: lastMove;
  background-color: hsl(26, 72%, 62%);
}

.lastMoveYellow {
  composes: lastMove;
  background-color: hsl(50, 62%, 65%);
}

.lastMoveForStaunty {
  composes: lastMove;
  opacity: 0.45;
  background-color: hsl(26, 72%, 62%);
  background-color: hsl(50, 75%, 50%);
  /* background-color: #00aa00; */
  /* background-color: hsl(0, 0%, 80%); */
  /* background-color: #1d8f10; */
}

.redSquare {
  background-color: #ff4900;
  opacity: 0.4;
}

.purpleSqare {
  background-color: #7d5d9c;
  opacity: 0.4;
}

.blueSquare {
  background-color: #6f95ba;
  opacity: 0.4;
  transition: background-color 0.3s;
}

.greenSquare {
  background-color: #00aa00;
  opacity: 0.4;
}
.redSquareAna {
  background-color: #ff4900;
}

.purpleSqareAna {
  background-color: #7d5d9c;
}

.blueSquareAna {
  background-color: #6f95ba;
  transition: background-color 0.3s;
}

.greenSquareAna {
  background-color: #00aa00;
}

@media only screen and (min-width: 768px) {
  /* .lastMove {
    background-color: yellow;
  } */

  .squareOverlay {
    opacity: 0.4;
    border: 3px solid rgba(33, 33, 33, 0.4);
    background-color: transparent;
  }
  .squareOverlayOrange {
    composes: squareOverlay;
  }
  .squareOverlayYellow {
    composes: squareOverlay;
  }
  .squareOverlayForStaunty {
    composes: squareOverlay;
  }
}
