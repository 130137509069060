.chesshotel {
  background-image: url("/images/board/chesshotel.svg");
}

.staunty {
  background-image: url("/images/board/brown.svg");
}

.brown {
  background-image: url("/images/board/brown.svg");
}

.brown-light {
  background-image: url("/images/board/brown-light.svg");
}
