.piece {
  background-size: contain;
  position: absolute;
  cursor: pointer;
  width: 12.5%;
  height: 12.5%;
  user-select: none;
  z-index: 20;
  will-change: transform;
  transform: translate3d(0, 0, 0);
}

.settings_piece {
  composes: piece;
  width: 25%;
  height: 33.3%;
}

.staunty_wP {
  background-image: url("/images/pieces/staunty/wP.svg");
}
.staunty_wN {
  background-image: url("/images/pieces/staunty/wN.svg");
}
.staunty_wB {
  background-image: url("/images/pieces/staunty/wB.svg");
}
.staunty_wR {
  background-image: url("/images/pieces/staunty/wR.svg");
}
.staunty_wQ {
  background-image: url("/images/pieces/staunty/wQ.svg");
}
.staunty_wK {
  background-image: url("/images/pieces/staunty/wK.svg");
}

.staunty_bP {
  background-image: url("/images/pieces/staunty/bP.svg");
}
.staunty_bN {
  background-image: url("/images/pieces/staunty/bN.svg");
}
.staunty_bB {
  background-image: url("/images/pieces/staunty/bB.svg");
}
.staunty_bR {
  background-image: url("/images/pieces/staunty/bR.svg");
}
.staunty_bQ {
  background-image: url("/images/pieces/staunty/bQ.svg");
}
.staunty_bK {
  background-image: url("/images/pieces/staunty/bK.svg");
}

.chesshotel_wP {
  background-image: url("/images/pieces/chesshotel/wP.svg");
}
.chesshotel_wN {
  background-image: url("/images/pieces/chesshotel/wN.svg");
}
.chesshotel_wB {
  background-image: url("/images/pieces/chesshotel/wB.svg");
}
.chesshotel_wR {
  background-image: url("/images/pieces/chesshotel/wR.svg");
}
.chesshotel_wQ {
  background-image: url("/images/pieces/chesshotel/wQ.svg");
}
.chesshotel_wK {
  background-image: url("/images/pieces/chesshotel/wK.svg");
}

.chesshotel_bP {
  background-image: url("/images/pieces/chesshotel/bP.svg");
}
.chesshotel_bN {
  background-image: url("/images/pieces/chesshotel/bN.svg");
}
.chesshotel_bB {
  background-image: url("/images/pieces/chesshotel/bB.svg");
}
.chesshotel_bR {
  background-image: url("/images/pieces/chesshotel/bR.svg");
}
.chesshotel_bQ {
  background-image: url("/images/pieces/chesshotel/bQ.svg");
}
.chesshotel_bK {
  background-image: url("/images/pieces/chesshotel/bK.svg");
}

.goast {
  opacity: 0.4;
  zindex: 0;
}

.opponent {
  cursor: default;
}

.square-11 {
  transform: translateY(700%);
}

.flipped .square-11 {
  transform: translate(700%);
}

.square-21 {
  transform: translate(100%, 700%);
}
.flipped .square-21 {
  transform: translate(600%);
}
.square-31 {
  transform: translate(200%, 700%);
}
.flipped .square-31 {
  transform: translate(500%);
}
.square-41 {
  transform: translate(300%, 700%);
}
.flipped .square-41 {
  transform: translate(400%);
}
.square-51 {
  transform: translate(400%, 700%);
}
.flipped .square-51 {
  transform: translate(300%);
}
.square-61 {
  transform: translate(500%, 700%);
}
.flipped .square-61 {
  transform: translate(200%);
}
.square-71 {
  transform: translate(600%, 700%);
}
.flipped .square-71 {
  transform: translate(100%);
}
.square-81 {
  transform: translate(700%, 700%);
}
.flipped .square-81 {
  transform: translate(0);
}
.square-12 {
  transform: translateY(600%);
}

.flipped .square-12 {
  transform: translate(700%, 100%);
}
.square-22 {
  transform: translate(100%, 600%);
}
.flipped .square-22 {
  transform: translate(600%, 100%);
}
.square-32 {
  transform: translate(200%, 600%);
}
.flipped .square-32 {
  transform: translate(500%, 100%);
}
.square-42 {
  transform: translate(300%, 600%);
}
.flipped .square-42 {
  transform: translate(400%, 100%);
}
.square-52 {
  transform: translate(400%, 600%);
}
.flipped .square-52 {
  transform: translate(300%, 100%);
}
.square-62 {
  transform: translate(500%, 600%);
}
.flipped .square-62 {
  transform: translate(200%, 100%);
}
.square-72 {
  transform: translate(600%, 600%);
}

.flipped .square-72 {
  transform: translate(100%, 100%);
}
.square-82 {
  transform: translate(700%, 600%);
}
.flipped .square-82 {
  transform: translateY(100%);
}
.square-13 {
  transform: translateY(500%);
}

.flipped .square-13 {
  transform: translate(700%, 200%);
}

.square-23 {
  transform: translate(100%, 500%);
}
.flipped .square-23 {
  transform: translate(600%, 200%);
}
.square-33 {
  transform: translate(200%, 500%);
}
.flipped .square-33 {
  transform: translate(500%, 200%);
}
.square-43 {
  transform: translate(300%, 500%);
}
.flipped .square-43 {
  transform: translate(400%, 200%);
}
.square-53 {
  transform: translate(400%, 500%);
}
.flipped .square-53 {
  transform: translate(300%, 200%);
}
.square-63 {
  transform: translate(500%, 500%);
}
.flipped .square-63 {
  transform: translate(200%, 200%);
}
.square-73 {
  transform: translate(600%, 500%);
}
.flipped .square-73 {
  transform: translate(100%, 200%);
}
.square-83 {
  transform: translate(700%, 500%);
}
.flipped .square-83 {
  transform: translateY(200%);
}
.square-14 {
  transform: translateY(400%);
}
.flipped .square-14 {
  transform: translate(700%, 300%);
}
.square-24 {
  transform: translate(100%, 400%);
}
.flipped .square-24 {
  transform: translate(600%, 300%);
}
.square-34 {
  transform: translate(200%, 400%);
}
.flipped .square-34 {
  transform: translate(500%, 300%);
}
.square-44 {
  transform: translate(300%, 400%);
}
.flipped .square-44 {
  transform: translate(400%, 300%);
}
.square-54 {
  transform: translate(400%, 400%);
}
.flipped .square-54 {
  transform: translate(300%, 300%);
}
.square-64 {
  transform: translate(500%, 400%);
}
.flipped .square-64 {
  transform: translate(200%, 300%);
}
.square-74 {
  transform: translate(600%, 400%);
}
.flipped .square-74 {
  transform: translate(100%, 300%);
}
.square-84 {
  transform: translate(700%, 400%);
}
.flipped .square-84 {
  transform: translateY(300%);
}
.square-15 {
  transform: translateY(300%);
}

.flipped .square-15 {
  transform: translate(700%, 400%);
}
.pseudo3d.flipped .square-15 {
  z-index: 5;
}
.square-25 {
  transform: translate(100%, 300%);
}
.pseudo3d .square-25 {
  z-index: 4;
}
.flipped .square-25 {
  transform: translate(600%, 400%);
}
.pseudo3d.flipped .square-25 {
  z-index: 5;
}
.square-35 {
  transform: translate(200%, 300%);
}
.pseudo3d .square-35 {
  z-index: 4;
}
.flipped .square-35 {
  transform: translate(500%, 400%);
}
.pseudo3d.flipped .square-35 {
  z-index: 5;
}
.square-45 {
  transform: translate(300%, 300%);
}
.pseudo3d .square-45 {
  z-index: 4;
}
.flipped .square-45 {
  transform: translate(400%, 400%);
}
.pseudo3d.flipped .square-45 {
  z-index: 5;
}
.square-55 {
  transform: translate(400%, 300%);
}
.pseudo3d .square-55 {
  z-index: 4;
}
.flipped .square-55 {
  transform: translate(300%, 400%);
}
.pseudo3d.flipped .square-55 {
  z-index: 5;
}
.square-65 {
  transform: translate(500%, 300%);
}
.pseudo3d .square-65 {
  z-index: 4;
}
.flipped .square-65 {
  transform: translate(200%, 400%);
}
.pseudo3d.flipped .square-65 {
  z-index: 5;
}
.square-75 {
  transform: translate(600%, 300%);
}
.pseudo3d .square-75 {
  z-index: 4;
}
.flipped .square-75 {
  transform: translate(100%, 400%);
}
.pseudo3d.flipped .square-75 {
  z-index: 5;
}
.square-85 {
  transform: translate(700%, 300%);
}
.pseudo3d .square-85 {
  z-index: 4;
}
.flipped .square-85 {
  transform: translateY(400%);
}
.pseudo3d.flipped .square-85 {
  z-index: 5;
}
.square-16 {
  transform: translateY(200%);
}
.pseudo3d .square-16 {
  z-index: 3;
}
.flipped .square-16 {
  transform: translate(700%, 500%);
}
.pseudo3d.flipped .square-16 {
  z-index: 6;
}
.square-26 {
  transform: translate(100%, 200%);
}
.pseudo3d .square-26 {
  z-index: 3;
}
.flipped .square-26 {
  transform: translate(600%, 500%);
}
.pseudo3d.flipped .square-26 {
  z-index: 6;
}
.square-36 {
  transform: translate(200%, 200%);
}
.pseudo3d .square-36 {
  z-index: 3;
}
.flipped .square-36 {
  transform: translate(500%, 500%);
}
.pseudo3d.flipped .square-36 {
  z-index: 6;
}
.square-46 {
  transform: translate(300%, 200%);
}
.pseudo3d .square-46 {
  z-index: 3;
}
.flipped .square-46 {
  transform: translate(400%, 500%);
}
.pseudo3d.flipped .square-46 {
  z-index: 6;
}
.square-56 {
  transform: translate(400%, 200%);
}
.pseudo3d .square-56 {
  z-index: 3;
}
.flipped .square-56 {
  transform: translate(300%, 500%);
}
.pseudo3d.flipped .square-56 {
  z-index: 6;
}
.square-66 {
  transform: translate(500%, 200%);
}
.pseudo3d .square-66 {
  z-index: 3;
}
.flipped .square-66 {
  transform: translate(200%, 500%);
}
.pseudo3d.flipped .square-66 {
  z-index: 6;
}
.square-76 {
  transform: translate(600%, 200%);
}
.pseudo3d .square-76 {
  z-index: 3;
}
.flipped .square-76 {
  transform: translate(100%, 500%);
}
.pseudo3d.flipped .square-76 {
  z-index: 6;
}
.square-86 {
  transform: translate(700%, 200%);
}
.pseudo3d .square-86 {
  z-index: 3;
}
.flipped .square-86 {
  transform: translateY(500%);
}
.pseudo3d.flipped .square-86 {
  z-index: 6;
}
.square-17 {
  transform: translateY(100%);
}
.pseudo3d .square-17 {
  z-index: 2;
}
.flipped .square-17 {
  transform: translate(700%, 600%);
}
.pseudo3d.flipped .square-17 {
  z-index: 7;
}
.square-27 {
  transform: translate(100%, 100%);
}
.pseudo3d .square-27 {
  z-index: 2;
}
.flipped .square-27 {
  transform: translate(600%, 600%);
}
.pseudo3d.flipped .square-27 {
  z-index: 7;
}
.square-37 {
  transform: translate(200%, 100%);
}
.pseudo3d .square-37 {
  z-index: 2;
}
.flipped .square-37 {
  transform: translate(500%, 600%);
}
.pseudo3d.flipped .square-37 {
  z-index: 7;
}
.square-47 {
  transform: translate(300%, 100%);
}
.pseudo3d .square-47 {
  z-index: 2;
}
.flipped .square-47 {
  transform: translate(400%, 600%);
}
.pseudo3d.flipped .square-47 {
  z-index: 7;
}
.square-57 {
  transform: translate(400%, 100%);
}
.pseudo3d .square-57 {
  z-index: 2;
}
.flipped .square-57 {
  transform: translate(300%, 600%);
}
.pseudo3d.flipped .square-57 {
  z-index: 7;
}
.square-67 {
  transform: translate(500%, 100%);
}
.pseudo3d .square-67 {
  z-index: 2;
}
.flipped .square-67 {
  transform: translate(200%, 600%);
}
.pseudo3d.flipped .square-67 {
  z-index: 7;
}
.square-77 {
  transform: translate(600%, 100%);
}
.pseudo3d .square-77 {
  z-index: 2;
}
.flipped .square-77 {
  transform: translate(100%, 600%);
}
.pseudo3d.flipped .square-77 {
  z-index: 7;
}
.square-87 {
  transform: translate(700%, 100%);
}
.pseudo3d .square-87 {
  z-index: 2;
}
.flipped .square-87 {
  transform: translateY(600%);
}
.pseudo3d.flipped .square-87 {
  z-index: 7;
}
.square-18 {
  transform: translate(0);
}
.pseudo3d .square-18 {
  z-index: 1;
}
.flipped .square-18 {
  transform: translate(700%, 700%);
}
.pseudo3d.flipped .square-18 {
  z-index: 8;
}
.square-28 {
  transform: translate(100%);
}
.pseudo3d .square-28 {
  z-index: 1;
}
.flipped .square-28 {
  transform: translate(600%, 700%);
}
.pseudo3d.flipped .square-28 {
  z-index: 8;
}
.square-38 {
  transform: translate(200%);
}
.pseudo3d .square-38 {
  z-index: 1;
}
.flipped .square-38 {
  transform: translate(500%, 700%);
}
.pseudo3d.flipped .square-38 {
  z-index: 8;
}
.square-48 {
  transform: translate(300%);
}
.pseudo3d .square-48 {
  z-index: 1;
}
.flipped .square-48 {
  transform: translate(400%, 700%);
}
.pseudo3d.flipped .square-48 {
  z-index: 8;
}
.square-58 {
  transform: translate(400%);
}
.pseudo3d .square-58 {
  z-index: 1;
}
.flipped .square-58 {
  transform: translate(300%, 700%);
}
.pseudo3d.flipped .square-58 {
  z-index: 8;
}
.square-68 {
  transform: translate(500%);
}
.pseudo3d .square-68 {
  z-index: 1;
}
.flipped .square-68 {
  transform: translate(200%, 700%);
}
.pseudo3d.flipped .square-68 {
  z-index: 8;
}
.square-78 {
  transform: translate(600%);
}
.pseudo3d .square-78 {
  z-index: 1;
}
.flipped .square-78 {
  transform: translate(100%, 700%);
}
.pseudo3d.flipped .square-78 {
  z-index: 8;
}
.square-88 {
  transform: translate(700%);
}
.pseudo3d .square-88 {
  z-index: 1;
}
.flipped .square-88 {
  transform: translateY(700%);
}
.pseudo3d.flipped .square-88 {
  z-index: 8;
}
